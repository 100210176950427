<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0" v-if="selectedEntite != null">{{selectedEntite.nom}} > Tous les contribuables</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Configurations</router-link></li>
            <li class="breadcrumb-item active">transactions</li>
          </ol>
        </div>
        <div class="page-title-right">
          <div>
<!--              <div style="display: inline">
                  <button v-if="checkDate('day')" @click="filterByDay" class="btn btn-primary ">
                      Aujourd'hui
                  </button>
                  <button v-else @click="filterByDay" class="btn btn-soft-secondary ">
                      Aujourd'hui
                  </button>
                  <button v-if="checkDate('week')" @click="filterByWeek" class="btn btn-primary ">
                      Cette semaine
                  </button>
                  <button v-else @click="filterByWeek" class="btn btn-soft-secondary ">
                      Cette semaine
                  </button>
                  <button v-if="checkDate('month')" @click="filterByMonth" class="btn btn-primary ">
                      Ce mois
                  </button>
                  <button v-else @click="filterByMonth" class="btn btn-soft-secondary ">
                      Ce mois
                  </button>
                  <button v-if="checkDate('year')" @click="filterByYear" class="btn btn-primary ">
                      Cette année
                  </button>
                  <button v-else @click="filterByYear" class="btn btn-soft-secondary ">
                      Cette année
                  </button>
                  <div class="btn-group" role="group">
                      <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle"
                              data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="mdi mdi-dots-vertical align-middle"></i>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                          <li>
                              <button class="dropdown-item" @click="filterByYesterday">Hier</button>
                          </li>
                          <li>
                              <button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button>
                          </li>
                          <li>
                              <button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button>
                          </li>
                          <li>
                              <Flatpickr
                                      @change="onDateChange"
                                      v-model="daterange"
                                      :config="config"
                                      class="form-control "
                                      placeholder="Select date"/>
                          </li>
                      </ul>
                  </div>
              </div>-->


          </div>

        </div>

      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
        </div>
        <div class="col-md-6">
          <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
        </div>

      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
        </div>
        <div class="col-md-4">
          <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
        </div>
        <div class="col-md-4">
          <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectSecteur($event)" @change="changeSecteur($event)"  />
        </div>
      </div>
      <div class="row mt-3">
        <form @submit.prevent="handleSearch">
          <div class="card">
            <div class="card-body">
              <table class="table table-sm table-bordered">
                <thead>
                <th>Champ</th>
                <th>Operation</th>
                <th>Valeur</th>
                </thead>
                <tbody>
                <tr v-for="(field, index) in fields" :key="index">
                  <td>
                    <select  v-model="field.colonne" class="form-select form-select-sm " style="width: 100%;">
                      <option v-bind:key="option.name" v-bind:value="option.name" v-for="option in repository.orderByOptions" >
                        {{ option.text }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select v-model="field.operator" class="form-select form-select-sm">
                      <option v-bind:key="option.name" v-for="option in repository.operatorOptions" v-bind:value="option.value">{{ option.text }}</option>
                    </select>
                  </td>
                  <td v-if="field.colonne === 'trans.statut' && field.operator==='eq' ">
                    <select v-model="field.value" class="form-select form-select-sm">
                      <option v-for="statut in repository.transStatutOptions" v-bind:value="statut" :key="statut">{{ statut }}</option>

                    </select>
                  </td>
                  <td v-else-if="field.colonne === 'trans.statut' && field.operator==='in' ">
                    <Select2MultipleControl @change="changeStatut($event, index)"  v-model="field.value" :options="repository.transStatutOptions"  class="form-select form-select-sm"></Select2MultipleControl>
                  </td>
                  <td v-else-if="field.colonne === 'ram.id' && field.operator==='eq' ">
                    <Select2 @change="changeStatut($event, index)"  v-model="field.value" :options="listRamifications.map(item => ({id: item.id, text: item.nom+' => ' + (item.parent != null)?item.parent.nom:''}))"  class="form-select form-select-sm"></Select2>
                  </td>
                  <td v-else-if="field.colonne === 'ram.id' && field.operator==='in' ">
                    <Select2MultipleControl @change="changeStatut($event, index)"  v-model="field.value" :options="ramifications"  class="form-select form-select-sm"></Select2MultipleControl>

                  </td>
                  <td v-else-if="field.colonne === 'trans.dateTransaction' && field.operator==='eq' ">
                    <Flatpickr
                        v-model="field.value"
                        :config="dateConfig"
                        class="form-control form-select-sm "
                        placeholder="Select date"/>
                  </td>
                  <td v-else-if="field.colonne === 'trans.dateTransaction' && field.operator==='like' ">
                    <Flatpickr
                        v-model="from"
                        @change="fromChange"
                        :config="dateConfig"
                        class="form-control form-select-sm"
                        placeholder="Select date"/>
                  </td>
                  <td v-else-if="field.colonne === 'trans.dateTransaction' && field.operator==='dateBetween' ">
                    <Flatpickr
                        v-model="field.x"
                        :config="dateConfig"
                        class="form-control form-select-sm"
                        placeholder="Select date"/>
                    <Flatpickr
                        v-model="field.y"
                        :config="dateConfig"
                        class="form-control form-select-sm"
                        placeholder="Select date"/>
                  </td>
                  <td v-else-if="field.colonne === 'ent.id' && field.operator==='eq' ">
                    <Select2  v-model="field.value" :options="collectivites"  class="form-select form-select-sm"></Select2>

                  </td>
                  <td v-else-if="field.colonne === 'ent.id' && field.operator==='in' ">
                    <Select2MultipleControl  @change="changeCollectivite($event, index)"   v-model="field.value" :options="collectivites"  class="form-select form-select-sm"></Select2MultipleControl>

                  </td>
                  <td v-else-if="field.colonne === 'user.id' && field.operator==='in' ">
                    <Select2MultipleControl  @change="changeCollectivite($event, index)"   v-model="field.value" :options="users"  class="form-select form-select-sm"></Select2MultipleControl>

                  </td>
                  <td v-else-if="field.colonne === 'user.id' && field.operator==='eq' ">
                    <Select2  @change="changeCollectivite($event, index)"   v-model="field.value" :options="users"  class="form-select form-select-sm"></Select2>

                  </td>
                  <td v-else-if="field.colonne === 'mp.id' && field.operator==='eq' ">
                    <Select2  @change="changeCollectivite($event, index)"   v-model="field.value" :options="moyenPaiement"  class="form-select form-select-sm"></Select2>

                  </td>
                  <td v-else-if="field.operator==='between' ">
                    <input v-model= "field.x" type="text" class="form-control form-control-sm">

                    <input v-model= "field.y" type="text" class="form-control form-control-sm">

                  </td>
                  <td v-else>
                    <input v-model= "field.value" type="text" class="form-control form-control-sm">
                  </td>
                  <td>
                    <a @click="addFields" class="btn btn-sm btn-success"><i class="ri-add-line"/> </a>
                    <a  @click="removeField(index)" class="btn btn-sm btn-danger"><i class="ri-delete-bin-2-line"/> </a>
                  </td>
                </tr>
                </tbody>
              </table>

              <div class=" text-end">
                <button type="submit" class="btn btn-success float-right">Rechercher</button>
              </div>
            </div>

          </div>
        </form>
      </div>


      <div class="row mt-3">
        <div class="card card-animate">

          <div class="card-body">
            <div class="row right p-2">
              <div class="col-md-2 mt-4">
                <div v-if="['Super Admin', 'Admin NTA'].includes(user.groupe.nom)" class="btn-group " role="group">
                  <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true">
                    Action
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" data-popper-placement="top-start" data-popper-reference-hidden="" data-popper-escaped="" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(0px, -40px);">
                    <li><a  @click="bulkDelete()" class="dropdown-item" href="#">Supprimer</a></li>
                    <li><a class="dropdown-item" href="#">Dropdown link</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-2">
                <form @submit.prevent="handleItemPerPage">
                  <label>Count:</label>
                  <select v-bind:value="itemsPerPage" v-on:change="handleItemPerPage" class="form-select" >
                    <option v-bind:key="option.name" v-for="option in repository.itemsPerPageOptions" v-bind:value="option.value" v-bind:selected="option.value === itemsPerPage">
                      {{ option.text }}
                    </option>
                  </select>

                </form>
              </div>
              <div class="col-md-2">
                <label>Sort:</label>
                <select v-on:change="handleSortOrder" v-model="sortOrder"  class="form-select  " >
                  <option v-bind:key="option.value" v-for="option in repository.sortOrderOptions" v-bind:value="option.value" v-bind:selected="option.value === sortOrder">
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Order By:</label>
                  <select v-on:change="handleOrderBy"   v-bind:value="orderBy" class="form-select  " style="width: 100%;">
                    <option v-bind:key="option.text" v-for="option in repository.orderByOptions" v-bind:value="option.name" v-bind:selected="option.value === orderBy">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 right mt-4 ">

              </div>


            </div>

            <div class="row">
              <div class="table-responsive">
                <div class="col-sm-auto text-start">
                  <div>
                    <button @click="exportToPDF" type="button" class="btn btn-success add-btn" data-bs-toggle="modal"
                            id="create-btn" data-bs-target="#showModal"><i class="ri-file-pdf-fill align-bottom me-1"></i>
                      PDF
                    </button>
                    &nbsp;<label>Afficher:</label>&nbsp;
                    <span v-for="nbre in repository.itemsPerPageOptions" :key="nbre">
                        <a v-if="itemsPerPage.toString()===nbre.text.toString()"
                           style="cursor: pointer; text-decoration: underline"
                           @click="handleItemPerPage(nbre.text)">{{ nbre.text }}</a>&nbsp;
                        <a v-else style="cursor: pointer;" @click="handleItemPerPage(nbre.text)">{{ nbre.text }}</a>&nbsp;
                      </span>
                  </div>
                </div>
                <table id="element-to-convert" class="table text-start mb-0 table-bordered">
                  <thead class="table-light">
                  <tr class="text-center" >
                    <th scope="col" class="bg-success">
                      <div class="form-check">
                        <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                        <label class="form-check-label" for="responsivetableCheck"></label>
                      </div>
                    </th>
                    <th class="bg-success" scope="col" >#</th>
                    <th  class="bg-success" scope="col" @click="sortTable('trans.dateTransaction')" aria-sort="ascending" style="cursor: pointer">Date <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>
                    <th  class="bg-success" scope="col" @click="sortTable('trans.dateTransaction')" aria-sort="ascending" style="cursor: pointer">Heure <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>

                    <th class="bg-success" scope="col" colspan="4">Contribuable</th>
                    <th class="bg-success" scope="col" colspan="4">Paiement</th>
                    <th class="bg-success" colspan="2" scope="col">Agent</th>
                    <th class="bg-success"></th>
                  </tr>
                  <tr>
                    <th></th>

                    <th></th>
                    <th></th>
                    <th></th>
                    <th @click="sortTable('contrib.nom')" aria-sort="ascending" style="cursor: pointer">Nom/Raison sociales <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>
                    <th @click="sortTable('contrib.numKlis')" aria-sort="ascending" style="cursor: pointer">N° KlisPay <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>

                    <th>Telephone</th>
                    <th>Emplacement</th>
                    <th>Moyen de paiement</th>
                    <th>Reference</th>
                    <th>Montant</th>
                    <th>Statut</th>
                    <th>Pseudo</th>
                    <th>Nom</th>
                    <th></th>

                  </tr>
                  </thead>
                  <tbody v-if="filteredTransactions.length > 0">
                  <tr  v-for="(item, index) in filteredTransactions" :key="item.id" >
                    <td></td>
                    <td>{{ index+1 }}</td>
                    <td>{{ item.dateRequete.toString().slice(0,10) }}</td>
                    <td>{{ item.dateRequete.toString().slice(11,16) }}</td>
                    <td v-if="item.contrib">
                      {{item.contrib.fullName}}
                    </td>
                    <td v-else>
                    </td>

                    <td v-if="item.contrib != null">{{item.contrib.numKlis}}</td>
                    <td v-else></td>
                    <td v-if="item.contrib">
                      {{item.contrib.telephone}}
                    </td>
                    <td v-else>
                    </td>
                    <td v-if="item.contrib.ramification != null">{{item.contrib.ramification.nom}}</td>
                    <td v-else></td>
                    <td>{{item.moyenPaiement.nom}}</td>
                    <td>{{item.noTransaction}}</td>
                    <td>{{formattedNumber(Number(item.montant))}}</td>
                    <td>{{item.statut}}</td>
                    <td v-if="item.bureauAccepteur != null">{{item.bureauAccepteur.username}}</td>
                    <td v-else></td>
                    <td v-if="item.bureauAccepteur != null">{{item.bureauAccepteur.fullName}}</td>
                    <td v-else></td>
                    <td>
                      <div class="dropdown">
                        <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="ri-more-2-fill"></i>
                        </a>

                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li><a  @click="deleteNatureActivite(item.id)" class="dropdown-item" >Supprimer</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>

                  </tbody>
                  <tbody v-else>
                  <tr >
                    <td colspan="11">En chargement....</td>
                  </tr>

                  </tbody>
                  <tfoot class="table-light">
                  <tr class="text-center " >
                    <th scope="col" class="bg-success">
                      <div class="form-check">
                        <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                        <label class="form-check-label" for="responsivetableCheck"></label>
                      </div>
                    </th>
                    <th class="bg-success" scope="col" >#</th>
                    <th class="bg-success" scope="col">Date</th>
                    <th class="bg-success" scope="col">Heure</th>
                    <th class="bg-success" scope="col" colspan="4">Contribuable</th>
                    <th class="bg-success" scope="col" colspan="4">Paiement</th>
                    <th class="bg-success" colspan="2" scope="col">Agent</th>
                    <th class="bg-success"></th>
                  </tr>



                  </tfoot>
                </table>
                <!-- end table -->
              </div>
            </div>
            <!-- end table responsive -->
          </div>
          <div class="card-footer">
            <div class="row">

              <div class="col-sm-12 col-md-12 ">
                <div style="display: flex; justify-content: flex-end" class="dataTables_paginate paging_simple_numbers text-end" id="scroll-horizontal_paginate">
                  <ul class="pagination">
                    <li v-if="totalPages<=1" class="paginate_button page-item previous disabled" id="scroll-horizontal_previous">
                      <a @click="goToPage((currentPage-1))" href="#" aria-controls="scroll-horizontal" data-dt-idx="0" tabindex="0" class="page-link">Précédent</a>
                    </li>
                    <li v-else-if="totalPages>1" class="paginate_button page-item previous disabled" id="scroll-horizontal_previous">
                      <a @click="goToPage((currentPage-1))" href="#" aria-controls="scroll-horizontal" data-dt-idx="0" tabindex="0" class="page-link">Précédent</a>
                    </li>
                    <li v-for="page in pages" :key="page" class="paginate_button page-item active">
                      <a  @click="goToPage(page)"   href="#" aria-controls="scroll-horizontal" data-dt-idx="1" tabindex="0" class="page-link">{{ page }}</a>
                    </li>
                    <li v-if="totalPages<=1" class="paginate_button page-item next disabled" id="scroll-horizontal_next">
                      <a @click="goToPage((currentPage+1))" href="#" aria-controls="scroll-horizontal" data-dt-idx="3" tabindex="0" class="page-link">Next</a>
                    </li>
                    <li v-else-if="totalPages>1" class="paginate_button page-item next " id="scroll-horizontal_next">
                      <a @click="goToPage((currentPage+1))" href="#" aria-controls="scroll-horizontal" data-dt-idx="3" tabindex="0" class="page-link">Next</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Repository from '@/repository/TransactionRepository'
import RamificationRepository from "@/repository/RamificationRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import store from "@/store";
import Select2 from 'vue3-select2-component';
import moment from "moment";
import Flatpickr from "vue-flatpickr-component";
import fr from "flatpickr/dist/l10n/fr";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import UserRepository from "@/repository/UserRepository";
import MoyenPaiementRepository from "@/repository/MoyenPaiementRepository";
import Select2MultipleControl from 'v-select2-multiple-component';
import html2pdf from "html2pdf.js";

export default {
  name: 'listCategorieActivitePage',
  data() {
    return {
      user: null,
      listCollectivites: [],
      ids: [],
      userRepo: new UserRepository(),
      moyenReop: new MoyenPaiementRepository(),
      listDepartements: [],
        selectedDepartement: null,
        selectedEntite: null,

      listQuartiers: [],
      listZones: [],
      listUsers: [],
      listMoyens: [],
      listSecteurs: [],
      dashboard: {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0,
        contribuableActuel: 0,
        prevuActuel: 0,
        paiementActuel:0
      },
      report: {},
      from: null,
      to: null,
      ms: [],
      daterange: null,
      repository: new Repository(),
      ramificationRepo: new RamificationRepository(),
      entiteRepo: new EntiteRepository(),
      orderBy: 'trans.dateTransaction',
      sortOrder: 'desc',
      itemsPerPage: 50,
      allSelected: false,
      selectedItems: [],
      listTransactions: [],
      selectedSecteur: null,
      totalPages: 1,
      currentPage: 1,
      loading: false,
      departement: null,
      entite: null,
      isLoading: false,
      quartier: null,
      zone:null,
      qt: [],
      secteur: null,
      fields: [
        { colonne: 'trans.statut', operator: 'eq', value: 'Confirmé' }
      ]

    }
  },
  computed: {
    ramifications(){
      return this.listRamifications.map(item=>({id: item.id, text: item.nom}))
    },
    moyenPaiement(){
      return this.listMoyens.map(item=>({id: item.id, text: item.nom}))
    },
    users(){
      return this.listUsers.map(item=>({id: item.id, text: item.fullName}))
    },
    collectivites(){
      return this.listCollectivites.map(item => ({id: item.id, text: item.text}));
    },
    dateConfig(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },
    config(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },

    filteredTransactions() {
      return this.listTransactions.filter(item => item.montant > 0);
    },
    formatDate(value){
      return moment(String(value)).format('DD/MM/YYYY')
    },
    pages() {
      let pages = Array.from({length: this.totalPages}, (_, i) => i + 1)
      let currentPage = this.currentPage
      let visiblePages = 5
      let start = currentPage - Math.floor(visiblePages / 2)
      let end = currentPage + Math.floor(visiblePages / 2)
      if (start < 1) {
        end += 1 - start
        start = 1
      }
      if (end > this.totalPages) {
        start -= end - this.totalPages
        end = this.totalPages
      }
      if (start > 1) {
        pages.splice(0, start - 1, '...')
      }
      if (end < this.totalPages) {
        pages.splice(end, this.totalPages - end, '...')
      }
      return pages.slice(start - 1, end)
    }

  },
  methods: {
      fromChange(){
          this.daterange = this.from + ' au ' + this.to
      },
      toChange(){
          this.daterange = this.from + ' au ' + this.to
      },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        html2canvas: {scale: 2},
        pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
        filename: "liste-transactions.pdf",
        jsPDF: {unit: 'mm', format: 'a0', orientation: 'landscape'}
      });
    },
      selectEntite(val){
          this.entite = val;
          this.selectedDepartement = null;
          this.listDepartements = [];
          //this.isLoading=true;
          this.selectedEntite = val.id;
          //var fields = this.fields;
          //this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ent.id'), 1)
          var index = this.fields.findIndex((obj) => obj.colonne === 'ent.id');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          this.fields.push({colonne: 'ent.id', operator: 'eq', value: val.id})

          this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
              .then(response => {
                  response.data.forEach(item => {
                      this.listDepartements.push({id: item.id, text: item.nom});
                      // this.isLoading=false;
                      this.fillTable()
                  })
              });
      },
      changeEntite(val){
          this.entite = val;
          this.selectedEntite = val;



      },
      changeDepartement(val) {
          this.selectedDepartement = val;
      },
      async selectDepartement(val) {
          //this.isLoading = true;
          this.ms = [];
          this.departement = val;
          this.dashboard = {
              prevu: 0,
              contribuables: 0,
              agents: 0, paiement: 0
          };
          this.listQuartiers = [];
          this.listZones = [];
          this.listSecteurs = [];
          this.ms.push(val.id.toString())

          await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 3000, 1)
              .then(response => {
                  response.data.forEach(item => {
                      this.listQuartiers.push({id: item.id, text: item.nom})

                  })
                  this.ms = this.listQuartiers.map(item => item.id);
                  this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.ms}], 'ent.nom', 'asc', 3000, 1)
                      .then(zone => {
                          zone.data.forEach(item => {
                              this.ms.push(item.id.toString())
                          })
                          this.listZones = zone.data.map(item => ({id: item.id, text: item.nom}));

                          this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.nom', 'asc', 3000, 1)
                              .then(secteurs => {
                                  secteurs.data.forEach(item => {
                                      this.ms.push(item.id)
                                  })
                                  var index = this.fields.findIndex((obj) => obj.colonne === 'ram.id');
                                  if (index !== -1) {
                                      this.fields.splice(index, 1);
                                  }
                                  index = this.fields.findIndex((obj) => obj.colonne === 'ent.id');
                                  if (index !== -1) {
                                      this.fields.splice(index, 1);
                                  }
                                  this.fields.push({colonne: 'ram.id', operator: 'in', value: this.ms})
                                  this.fillTable();

                              })



                      })





              })
      },
      changeStatut(val, index) {
          this.fields[index].value = val;
          console.log(val)

      },
      changeCollectivite(val, index) {
          this.fields[index].value = val;
          console.log(val)

      },
      changeRamification(val, index) {
          this.fields[index].value = val;
          console.log(val)

      },
      changeQuartier(val) {
          this.selectedQuartier = val;
      },
      selectQuartier(val) {
          this.ms = [];
          //this.isLoading=true;
          this.quartier = val;
          this.dashboard = {
              prevu: 0,
              contribuables: 0,
              agents: 0,
              paiement: 0
          };
          this.listZones = [];
          this.listSecteurs = [];
          this.ms.push(val.id.toString())
          this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
              .then(response => {
                  response.data.forEach(item => {
                      this.listZones.push({id: item.id, text: item.nom})
                      this.ms.push(item.id.toString());
                  })

                  this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.id', 'asc', 3000, 1)
                      .then(secteurs => {
                          secteurs.data.forEach(item => {
                              this.ms.push(item.id.toString());
                          })
                          var index = this.fields.findIndex((obj) => obj.colonne === 'ram.id');
                          if (index !== -1) {
                              this.fields.splice(index, 1);
                          }
                          index = this.fields.findIndex((obj) => obj.colonne === 'ent.id');
                          if (index !== -1) {
                              this.fields.splice(index, 1);
                          }
                          this.fields.push({colonne: 'ram.id', operator: 'in', value: this.ms})
                          this.fillTable()

                      })
                  //this.ms.push(this.listZones.map(item => item.id))

              })


      },
      changeZone(val) {
          this.selectedZone = val;
      },
      selectZone(val) {
          // this.isLoading=true;
          this.zone = val;
          this.dashboard = {
              prevu: 0,
              contribuables: 0,
              agents: 0,
              paiement: 0
          };
          this.listSecteurs = [];
          this.ms = [];
          this.ms.push(val.id)
          this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
              .then(response => {
                  response.data.forEach(item => {
                      this.listSecteurs.push({id: item.id, text: item.nom})
                      this.ms.push(item.id)
                  })
                  var index = this.fields.findIndex((obj) => obj.colonne === 'ram.id');
                  if (index !== -1) {
                      this.fields.splice(index, 1);
                  }
                  index = this.fields.findIndex((obj) => obj.colonne === 'ent.id');
                  if (index !== -1) {
                      this.fields.splice(index, 1);
                  }
                  this.fields.push({colonne: 'ram.id', operator: 'in', value: this.ms})
                  this.fillTable()
              })

      },
      getDatesInRange(startDate, endDate) {
          const date = new Date(startDate.getTime());

          const dates = [];

          while (date <= endDate) {
              dates.push(moment(date).format('YYYY-MM-DD'));
              date.setDate(date.getDate() + 1);
          }

          return dates;
      },

    sortTable(colonne){
      this.orderBy = colonne;
      if (this.sortOrder === 'asc')
        this.sortOrder = 'desc';
      else
        this.sortOrder = 'asc'
      this.fillTable()

    },
      onDateChange()
      {
          this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
          this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange;
          var index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }

          this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to})
          this.fillTable();
      },
      filterByDay() {
          this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
          this.from = this.to = moment().format("YYYY-MM-DD")
          var index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to})

          this.fillTable();
          // console.log(this.daterange)
      },
      filterByYesterday() {
          this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
          this.from = moment().subtract(1, 'days').format("YYYY-MM-DD")
          this.to = moment().subtract(1, 'days').format("YYYY-MM-DD")
          var index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to})
          this.fillTable();
          //console.log(this.daterange)
      },
      filterByLastWeek() {
          this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
          this.from = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD")
          this.to = moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
          var index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to})
          this.fillTable();
          //console.log(this.daterange)
      },
      filterByLastMonth() {
          this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
          this.from = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD")
          this.to = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")
          var index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to})
          this.fillTable();
          //console.log(this.daterange)
      },
      filterByWeek() {

          this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek').format("YYYY-MM-DD");
          this.from = moment().startOf('isoWeek').format("YYYY-MM-DD")
          this.to = moment().endOf('isoWeek').format("YYYY-MM-DD")
          // console.log(this.daterange);
          var index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to})
          this.fillTable();
      },
      filterByMonth() {
          this.daterange = moment().startOf('month').format("YYYY-MM-DD") + ' au ' + moment().endOf('month').format("YYYY-MM-DD");
          this.from = moment().startOf('month').format("YYYY-MM-DD")
          this.to = moment().endOf('month').format("YYYY-MM-DD")
          var index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to})
          this.fillTable();
      },
      filterByYear() {
          this.daterange = moment().startOf('year').format("YYYY-MM-DD") + ' au ' + moment().endOf('year').format("YYYY-MM-DD");
          this.from = moment().startOf('year').format("YYYY-MM-DD")
          this.to = moment().endOf('year').format("YYYY-MM-DD")
          var index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          index = this.fields.findIndex((obj) => obj.colonne === 'trans.dateTransaction');
          if (index !== -1) {
              this.fields.splice(index, 1);
          }
          this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to})
          this.fillTable();
          // console.log(' au ' + moment().endOf('year').format("YYYY-MM-DD"))
      },
      formattedNumber(number) {
          if (number != null)
              return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return 0;
      },

      checkDate(type) {
          if (type === 'day')
              return this.daterange === moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD") || this.daterange === moment().format("YYYY-MM-DD")
          if (type === 'week')
              return this.daterange === moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek').format("YYYY-MM-DD")
          if (type === 'month')
              return this.daterange === moment().startOf('month').format("YYYY-MM-DD") + ' au ' + moment().endOf('month').format("YYYY-MM-DD")
          if (type === 'year')
              return this.daterange === moment().startOf('year').format("YYYY-MM-DD") + ' au ' + moment().endOf('year').format("YYYY-MM-DD")

      },

    deleteNatureActivite(id){
      if(confirm("Etes-vous sûr de supprimer cet élément ?")){
        this.loading = true;
        this.repository.supprimer(id);
        this.fillTable();
        this.loading = false;
      }


    },
    bulkDelete(){
      if(confirm("Etes vous sûr de vouloir supprimer cet élément ?")){
        this.loading = true;

        this.selectedItems.forEach(element => {
          this.repository.supprimer(element);
          //console.log(element);
        })
        this.loading = false;
        this.fillTable();
      }

    },
    selectAll() {
      if (this.allSelected === false){
        this.allSelected = true;
        this.selectedItems = this.listTransactions.map(type => type.id);
        //  console.log(this.selectedItems);
      }
      else{
        this.allSelected = false;
        this.selectedItems = [];

        // console.log(this.selectedItems);

      }

      //console.log(this.allSelected)
    },
    goToPage(page){
      this.currentPage = page;
      this.fillTable();
    },
    addFields() {
      this.fields.push({ colonne: '', operator: '', value: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    handleItemPerPage(event){
      this.itemsPerPage = event.target.value;
      //   console.log('total de page ' + this.pages)
      // console.log(this.itemsPerPage)
      this.currentPage=1;

      this.fillTable();


    },
    handleSearch(){
      //console.log(this.fields);
      this.currentPage = 1;
      this.fillTable();
    },

    handleOrderBy(event){
      this.orderBy = event.target.value;
      this.fillTable();

      //console.log(this.orderBy)

    },
    handleSortOrder(event){
      this.sortOrder = event.target.value;
      // console.log(this.sortOrder)
      this.fillTable();

    },
    async fillTable(){
      try {
        this.isLoading = true;

          var index = this.fields.findIndex((obj) => obj.colonne === 'contrib.nbreBetail');

          if (index !== -1) {
              this.fields.splice(index, 1);
          }

        const trResponse = await this.repository.search(this.fields, this.orderBy, this.sortOrder, this.itemsPerPage, this.currentPage);
        //console.log(trResponse)
        this.listTransactions = trResponse.data;
        //console.log(this.listTransactions);
        this.totalPages = Math.ceil(trResponse.meta.total_records / this.itemsPerPage);
        this.isLoading = false;

      }catch (e) {
        console.log(e);
      }
    },


  },
  created() {
    this.selectedEntite = store.state.selectedCollectivite;
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment(). format("YYYY-MM-DD");
    this.from = this.to = moment().format("YYYY-MM-DD")
      if(this.$route.query.from){
          this.from = this.$route.query.from
          this.to = this.$route.query.to
      }
      this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y:this.to});



    this.userRepo.search([{colonne: '', operator: '', value: ''}], 'user.username', 'asc', 3000, 1)
        .then(response => {
          this.listUsers = response.data;
          // this.fillTable();
        })
    this.moyenReop.search([{colonne: 'mp.enabled', operator: 'eq', value: 1}], 'mp.nom', 'asc', 3000, 1)
        .then(response => {
          this.listMoyens = response.data;
          //     this.fillTable();
        })
    this.ramificationRepo.search([{colonne: 'ram.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listRamifications = response.data;
            if(this.$route.query.ms){
                //this.entite = this.listCollectivites.find(item => item.id === Number())
                this.fields.push({colonne: 'ram.id', operator: 'in', value: JSON.parse(this.$route.query.ms)})
            }
          //     this.fillTable();
        })
    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});

          })
            if(this.$route.query.entite){
                this.entite = this.listCollectivites.find(item => item.id === Number(this.$route.query.entite))
                this.fields.push({colonne: 'ent.id', operator: 'in', value: JSON.parse(this.$route.query.entite)})
            }

           // console.log(this.listCollectivites.find(item => item.id === Number(this.$route.query.entite)) )

          this.fillTable();

        })

    //this.fillTable();
  },
  components:{
    Select2,
    Flatpickr,
    Loading,
    Select2MultipleControl

  }
}
</script>