<template>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 class="mb-sm-0">Autres Organisations</h4>
  
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
              <li class="breadcrumb-item active">Autres organisation</li>
            </ol>
          </div>
  
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <form @submit.prevent="handleSearch">
            <div class="card">
              <div class="card-body">
                <table class="table table-sm table-bordered">
                  <thead>
                  <th>Champ</th>
                  <th>Operation</th>
                  <th>Valeur</th>
                  </thead>
                  <tbody>
                  <tr v-for="(field, index) in fields" :key="index">
                    <td>
                      <select  v-model="field.colonne" class="form-select form-select-sm " style="width: 100%;">
                        <option v-bind:key="option.name" v-bind:value="option.name" v-for="option in repository.orderByOptions" >
                          {{ option.text }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select v-model="field.operator" class="form-select form-select-sm">
                        <option v-bind:key="option.name" v-for="option in repository.operatorOptions" v-bind:value="option.value">{{ option.text }}</option>
                      </select>
                    </td>
                    <td>
                      <input style="width: 100%" v-model="field.value" type="text" class="form-control-sm">
                    </td>
                    <td>
                      <a @click="addFields" class="btn btn-sm btn-success"><i class="ri-add-line"/> </a>
                      <a v-if="index > 0" @click="removeField(index)" class="btn btn-sm btn-danger"><i class="ri-delete-bin-2-line"/> </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class=" text-end">
                  <button type="submit" class="btn btn-success float-right">Rechercher</button>
                </div>
              </div>
  
            </div>
          </form>
        </div>
        <div class="row">
          <div class="card card-animate">
  
            <div class="card-body">
              <div class="row right p-2">
                <div class="col-md-2 mt-4">
                  <div class="btn-group " role="group">
                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true">
                      Action
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" data-popper-placement="top-start" data-popper-reference-hidden="" data-popper-escaped="" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(0px, -40px);">
                      <li><a  @click="bulkDelete()" class="dropdown-item" href="#">Supprimer</a></li>
                      <li><a class="dropdown-item" href="#">Dropdown link</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-2">
                  <form @submit.prevent="handleItemPerPage">
                    <label>Count:</label>
                    <select v-bind:value="itemsPerPage" v-on:change="handleItemPerPage" class="form-select" >
                      <option v-bind:key="option.name" v-for="option in repository.itemsPerPageOptions" v-bind:value="option.value" v-bind:selected="option.value === itemsPerPage">
                        {{ option.text }}
                      </option>
                    </select>
  
                  </form>
                </div>
                <div class="col-md-2">
                  <label>Sort:</label>
                  <select v-on:change="handleSortOrder" v-model="sortOrder"  class="form-select  " >
                    <option v-bind:key="option.value" v-for="option in repository.sortOrderOptions" v-bind:value="option.value" v-bind:selected="option.value === sortOrder">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Order By:</label>
                    <select v-on:change="handleOrderBy"   v-bind:value="orderBy" class="form-select  " style="width: 100%;">
                      <option v-bind:key="option.text" v-for="option in repository.orderByOptions" v-bind:value="option.name" v-bind:selected="option.value === orderBy">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 right mt-4 ">
                  <router-link :to="{ name: 'createAutreOrganisation' }" class="btn btn-block btn-outline-primary"><i class="ri-add-fill ri-1x"></i> Creation</router-link>
                </div>
  
  
              </div>
  
              <div class="row">
                <div class="">
                  <table class="table text-start mb-0">
                    <thead class="table-light">
                    <tr>
                      <th scope="col">
                        <div class="form-check">
                          <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                          <label class="form-check-label" for="responsivetableCheck"></label>
                        </div>
                      </th>
                      <th scope="col">#</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Adresse</th>
                      <th scope="col">Telephone</th>
                      <th scope="col">Ville</th>
                      <th scope="col">Statut</th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <td colspan="7"></td>

                      <td></td>
  
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in listEntites" :key="item.id">
                      <td><input class="form-check-input" type="checkbox" v-model="selectedItems" v-bind:value="item.id" /></td>
                      <td>{{ index+1 }}</td>
                      <td>{{item.nom}} </td>
                      <td>{{item.adresse}}</td>
                      <td>{{item.telephone}}</td>
                      <td>{{item.ville.nom}}</td>
  
                      <td>
                        <span v-if="item.enabled===true" class="badge bg-success">Active</span>
                        <span v-else class="badge bg-danger">inactive</span>
                      </td>

                      <td>
                        <div class="dropdown">
                          <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>
  
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><router-link :to="{ name: 'editCollectivite', params: { id: item.id } }" class="dropdown-item" ><i class="ri-pencil-fill r-2x"></i> Modifier</router-link></li>
                            <li><router-link :to="{ name: 'createCollectiviteUser', params: { id: item.id } }" class="dropdown-item" ><i class="ri-pencil-fill r-2x"></i> Ajouter un utilisateur</router-link></li>
                            <li><button @click="deleteCategorie(item.id)" class="dropdown-item" >Supprimer</button></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
  
                    </tbody>
                    <tfoot class="table-light">
                    <tr>
                      <th scope="col">
                        <div class="form-check">
                          <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                          <label class="form-check-label" for="responsivetableCheck"></label>
                        </div>
                      </th>
                      <th scope="col">#</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Adresse</th>
                      <th scope="col">Telephone</th>
                      <th scope="col">Ville</th>
                      <th scope="col">Statut</th>
                      <th></th>
                      <th></th>
                    </tr>
  
                    </tfoot>
                  </table>
                  <!-- end table -->
                </div>
              </div>
              <!-- end table responsive -->
            </div>
            <div class="card-footer">
              <div class="row">
  
                <div class="col-sm-12 col-md-12 ">
                  <div style="display: flex; justify-content: flex-end" class="dataTables_paginate paging_simple_numbers text-end" id="scroll-horizontal_paginate">
                    <ul class="pagination">
                      <li v-if="totalPages > currentPage " class="paginate_button page-item previous " id="scroll-horizontal_previous">
                        <button @click="goToPage((currentPage-1))"  aria-controls="scroll-horizontal" data-dt-idx="0" tabindex="0" class="page-link">Précédent</button>
                      </li>
                      <li v-else-if="totalPages=== currentPage" class="paginate_button page-item previous disabled" id="scroll-horizontal_previous">
                        <button @click="goToPage((currentPage-1))"  aria-controls="scroll-horizontal" data-dt-idx="0" tabindex="0" class="page-link">Précédent</button>
                      </li>
                      <li v-for="page in pages" :key="page" class="paginate_button page-item active">
                        <button  @click="goToPage(page)"    aria-controls="scroll-horizontal" data-dt-idx="1" tabindex="0" class="page-link">{{ page }}</button>
                      </li>
                      <li v-if="totalPages<=1" class="paginate_button page-item next disabled" id="scroll-horizontal_next">
                        <button @click="goToPage((currentPage+1))"  aria-controls="scroll-horizontal" data-dt-idx="3" tabindex="0" class="page-link">Next</button>
                      </li>
                      <li v-else-if="totalPages>1" class="paginate_button page-item next " id="scroll-horizontal_next">
                        <button @click="goToPage((currentPage+1))"  aria-controls="scroll-horizontal" data-dt-idx="3" tabindex="0" class="page-link">Next</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </template>
  <script>
  import Repository from "@/repository/EntiteRepository";
  
  export default {
    name: 'listeCollectivite',
    data(){
      return{
        user: null,
        ids: [],
        repository: new Repository(),
        orderBy: 'ent.nom',
        sortOrder: 'asc',
        itemsPerPage: 10,
        allSelected: false,
        selectedItems: [],
        selectedCollectivite: null,
        listEntites: null,
        totalPages: 1,
        currentPage: 1,
        loading: false,
        fields: [
          { colonne: '', operator: '', value: '' }
        ]
      }
    },
    computed: {
      pages() {
        // Calculer le nombre de pages en arrondissant à l'entier supérieur
  
        // Créer un tableau allant de 1 à numPages
        return Array.from({length: this.totalPages}, (_, i) => i + 1)
      }
    },
    methods: {
      deleteCategorie(id){
        if(confirm("Etes-vous sûr de supprimer cet élément ?")){
          this.repository.supprimer(id);
          this.fillTable();
        }
  
  
      },
      bulkDelete(){
        if(confirm("Etes vous sûr de vouloir supprimer cet élément ?")){
          this.loading = true;
  
          this.selectedItems.forEach(element => {
            this.repository.supprimer(element);
            //console.log(element);
          })
          this.loading = false;
          this.fillTable();
        }
  
      },
      selectAll() {
        if (this.allSelected === false){
          this.allSelected = true;
          this.selectedItems = this.listEntites.map(type => type.id);
          //  console.log(this.selectedItems);
        }
        else{
          this.allSelected = false;
          this.selectedItems = [];
  
          // console.log(this.selectedItems);
  
        }
  
        //console.log(this.allSelected)
      },
      goToPage(page){
        this.currentPage = page;
        this.fillTable();
      },
      addFields() {
        this.fields.push({ colonne: '', operator: '', value: '' });
      },
      removeField(index) {
        this.fields.splice(index, 1);
      },
      handleItemPerPage(event){
        this.itemsPerPage = event.target.value;
        //   console.log('total de page ' + this.pages)
        // console.log(this.itemsPerPage)
        this.currentPage=1;
  
        this.fillTable();
  
  
      },
      handleSearch(){
        //console.log(this.fields);
        this.currentPage = 1;
        this.fillTable();
      },
  
      handleOrderBy(event){
        this.orderBy = event.target.value;
        this.fillTable();
  
        //console.log(this.orderBy)
  
      },
      handleSortOrder(event){
        this.sortOrder = event.target.value;
        // console.log(this.sortOrder)
        this.fillTable();
  
      },
      async fillTable(){
        try {
          const trResponse = await this.repository.search(
              this.fields,
              this.orderBy, this.sortOrder, this.itemsPerPage, this.currentPage
          );
          this.listEntites = trResponse.data;
          this.totalPages = Math.ceil(trResponse.meta.total_records / this.itemsPerPage);
  
  
  
          //console.log(this.listUtilisateurs);
  
        }catch (e) {
          console.log(e);
        }
      },
  
    },
    created() {
      this.selectedCollectivite = this.$store.state.selectedCollectivite;
      this.user = this.$store.state.user;
      this.fillTable();
  
  
    }
  }
  </script>