<template>
    <loading v-model:active="isLoading"
             :can-cancel="false"
             :on-cancel="onCancel"
             :is-full-page="fullPage"/>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0">
                    <span v-if="entite !=null">{{ entite.text }}</span>
                    <span v-if="departement !=null"> > {{ departement.text }}</span>
                    <span v-if="quartier !=null"> > {{ quartier.text }}</span>
                    <span v-if="zone !=null"> > {{ zone.text }}</span>
                </h4>
                <div class="page-title-right">
                    <div>
                        <div style="display: inline">
                            <button v-if="checkDate('day')"   @click="filterByDay" class="btn btn-primary ">
                                Aujourd'hui
                            </button>
                            <button v-else   @click="filterByDay" class="btn btn-soft-secondary ">
                                Aujourd'hui
                            </button>
                            <button  v-if="checkDate('week')"   @click="filterByWeek" class="btn btn-primary ">
                                Cette semaine
                            </button>
                            <button  v-else   @click="filterByWeek" class="btn btn-soft-secondary ">
                                Cette semaine
                            </button>
                            <button v-if="checkDate('month')"  @click="filterByMonth" class="btn btn-primary ">
                                Ce mois
                            </button>
                            <button v-else  @click="filterByMonth" class="btn btn-soft-secondary ">
                                Ce mois
                            </button>
                            <button v-if="checkDate('year')"  @click="filterByYear" class="btn btn-primary ">
                                Cette année
                            </button>
                            <button v-else  @click="filterByYear" class="btn btn-soft-secondary ">
                                Cette année
                            </button>
                            <div  class="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="mdi mdi-dots-vertical align-middle"></i>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                    <li><button class="dropdown-item" @click="filterByYesterday">Hier</button></li>
                                    <li><button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button></li>
                                    <li><button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button></li>
                                    <li><Flatpickr
                                            @change="onDateChange"
                                            v-model="daterange"
                                            :config="config"
                                            class="form-control "
                                            placeholder="Select date"/></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
        </div>
        <div class="col-md-6">
            <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
        </div>

    </div>
    <div class="row mt-2">
        <div class="col-md-4">
            <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
        </div>
        <div class="col-md-4">
            <Select2 :placeholder="'Toutes les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
        </div>
        <div class="col-md-4">
            <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectSecteur($event)" @change="changeSecteur($event)"  />
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <div class="card crm-widget">
                <div class="card-body p-0">
                    <div class="row row-cols-xxl-6">
                        <div class="col text-start bg-warning">
                            <div class="py-3 px-2">
                                <h5 class=" text-uppercase fs-13">Contribuable(s) enrôlé(s) </h5>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="ri-user-3-line display-6 text-white"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-0"><span class="counter-value" data-target="{{ formattedNumber(dashboard.contribuables) }}"><router-link style="color: #fff" :to="{name: 'listeContribuables'}">{{ formattedNumber(dashboard.contribuables) }}</router-link></span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col text-start bg-primary ">
                            <div class="py-3 px-2">
                                <h5 class=" text-uppercase fs-13 text-white">Equipement(s) enrôlé(s) </h5>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="ri-user-3-line display-6 text-white"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-0"><span class="counter-value" data-target="{{ formattedNumber(dashboard.nbre_equipement) }}"><router-link style="color: #fff" :to="{name: 'listeEquipements'}">{{ formattedNumber(dashboard.nbre_equipement) }}</router-link></span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col text-start bg-info">
                            <div class="py-3 px-2 text-white">
                                <h5 class="text-uppercase fs-13 text-white">Assiète </h5>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="ri-money-dollar-box-line display-6 text-white"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <!--                    <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu}}">{{ formattedNumber(dashboard.prevu) }}</span></h2>-->
                                        <h2>
                                            <router-link style="color: #fff"
                                                         to="{name: 'collecte', query: {'entite': JSON.stringify(ids), 'ms': JSON.stringify(this.ms), from: this.from, to: this.to} }">
                                                {{ formattedNumber(Number(dashboard.prevu))}}
                                            </router-link>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div><!-- end col -->
                        <div class="col text-start bg-danger">
                            <div class="py-3 px-2">
                                <h5 class=" text-uppercase fs-13 text-white">Montant Restant </h5>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="ri-money-dollar-box-line display-6 text-white"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <!--                    <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu-dashboard.paiement}}">{{ formattedNumber(Math.round((dashboard.prevu-(Number(dashboard.paiement) - ((Number(dashboard.paiement)*25))/100)) ))}}</span></h2>-->
                                        <h2>
                                            <router-link
                                                    style="color: #fff"
                                                    :to="{name: 'detailsContribuables', query: {'entite': this.entite, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to} }">
                                                {{formattedNumber(Number((Number(dashboard.prevu) - Number(dashboard.paiement))))}}
                                            </router-link>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div><!-- end col -->

                        <div class="col text-start bg-success">
                            <div class="py-3 px-2">
                                <h5 class="text-uppercase fs-13 text-white">Montant payé <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="ri-money-dollar-box-line display-6 text-white"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <!--                    <h2 class="mb-0"><span class="counter-value text-white" data-target="197">{{ formattedNumber(totalPayer) }}</span></h2>-->
                                        <h2>
                                            <router-link
                                                    style="color: #fff"
                                                    :to="{name: 'listeTransactions', query: {'entite': (this.entite != null)?this.entite.id:null, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to} }">
                                                {{ formattedNumber(Number(dashboard.paiement)) }}
                                            </router-link>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div><!-- end col -->
                        <div class="col text-start" style="background: #04414d">
                            <div class="py-3 px-2">
                                <h5 class=" text-uppercase fs-13 text-white">Part NTA </h5>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="ri-money-dollar-box-line display-6 text-white"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-0" style="color: #fff"><span style="color: #fff !important;" class="counter-value text-white" data-target="{{dashboard.paiement}}">{{ formattedNumber(Math.round((Number(dashboard.paiement)*25)/100))}}</span></h2>

                                    </div>
                                </div>
                            </div>
                        </div><!-- end col -->
                    </div><!-- end row -->
                </div><!-- end card body -->
            </div><!-- end card -->
        </div><!-- end col -->
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <table class="table table-bordered text-start text-center">
                        <thead>
                        <tr class=" ">
                            <th class="bg-success text-white text-center" rowspan="2">Ramification</th>
                            <th class="bg-success text-white">Nombre <br> Enrôlement</th>
                            <th class="bg-success text-white" style="vertical-align: top">Montant <br>Prévu</th>
                            <th class="bg-success text-white">Montant <br>Payé</th>
                            <th  class="bg-success text-white">Montant <br> Restant</th>
                            <th  class="bg-success text-white">Montant <br> Dû</th>

                        </tr>
                        </thead>
                        <tbody >
                        <tr v-for="item in tableau" :key="item">
                            <td style="font-weight: bold" class="text-center bold bg-success text-white ">{{item.nom}}</td>
                            <td style="font-weight: bold"  class="text-end bg-success text-white">{{formattedNumber(item.contribuables)}}</td>
                            <!--<td>0</td>-->
                            <td style="font-weight: bold"  class="text-end bg-success text-white">{{formattedNumber(item.prevu)}}</td>
                            <td style="font-weight: bold"  class="text-end bg-success text-white">{{ formattedNumber(Number(item.paiement)) }}</td>
                            <td style="font-weight: bold"  class="text-end bg-success text-white">{{formattedNumber(Number((Number(item.prevu) - Number(item.paiement))))}}</td>
                            <td style="font-weight: bold"  class="text-end bg-success text-white"></td>



                        </tr>
                        </tbody>
                        <tfoot class=" ">
                        <tr class="">
                            <th class="bg-success text-white">TOTAL</th>
                            <th class="text-end bg-success text-white">{{formattedNumber(totalContribuable)}}</th>
                            <th class="text-end bg-success text-white">{{formattedNumber(totalPrevu)}}</th>
                            <th class="text-end bg-success text-white">{{formattedNumber(totalPaiement)}}</th>
                            <th class="text-end bg-success text-white">{{formattedNumber(Number(totalRestant))}}</th>
                            <th class="text-end bg-success text-white"></th>


                        </tr>
                        </tfoot>
                    </table>

                </div>
            </div>
        </div>

    </div>


</template>
<script>
import Select2 from 'vue3-select2-component';
import fr from "flatpickr/dist/l10n/fr";
import Flatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import EntiteRepository from "@/repository/EntiteRepository";
import RamificationRepository from "@/repository/RamificationRepository";
import DashboardRepository from "@/repository/DashboardRepository";
import Loading from "vue-loading-overlay";
import moment from "moment";
export default {
    name: 'DashboardPage',
    computed: {

        config(){
            //var current_month = 12 - new Date().getMonth();
            return   {
                locale: fr.fr,
                mode: "range",
                dateFormat: "Y-m-d",
                firstDayOfWeek: 1,
                defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee +"-" +new Date().getMonth()+"-01", this.selectedAnnee +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
            }

        },
        totalPayer(){
            var total = Number(this.dashboard.paiement) - (((Number(this.dashboard.paiement)*25))/100);
            return Math.round(total);
        },
        totalContribuable()
        {
            let sum = 0;
            for (const item of this.tableau) {
                sum += (item != null) ? parseInt(item.contribuables) : 0;
            }
            return sum;
        },

        totalEquipement()
        {
            let sum = 0;
            for (const item of this.tableau) {
                sum += (item != null) ? parseInt(item.nbre_equipement) : 0;
            }
            return sum;
        },


        totalPrevu()
        {
            let sum = 0;
            for (const item of this.tableau) {
                sum += (item != null) ? Number(item.prevu) : 0;
            }
            return sum;
        },

        totalPaiement()
        {
            let sum = 0;
            for (const item of this.tableau) {
                sum += (item != null) ? Number(item.paiement) : 0;
            }
            return sum;
        },
        totalPaiementPeriode()
        {
            let sum = 0;
            for (const item of this.tableau) {
                sum += (item != null) ? Number(item.paiement) : 0;
            }
            return sum;
        },
        totalRestant()
        {
            let sum = 0;
            let totalPrevu = 0;
            let totalPaiement = 0;
            for (const item of this.tableau) {
                totalPrevu += (item != null) ? Number(item.prevu) : 0;
            }
            for (const item of this.tableau) {
                totalPaiement += (item != null) ? Number(item.paiement) : 0;
            }
            sum = totalPrevu - totalPaiement
            return sum;
        },
        totalAgent()
        {
            let sum = 0;
            for (const item of this.tableau) {
                sum += (item != null) ? parseInt(item.agent) : 0;
            }
            return sum;
        }
    },
    data(){
        return {
            isLoading: false,
            fullPage: true,
            selectedCollectivite: null,
            user: null,
            ids: [],
            dashboard: {
                prevu: 0,
                contribuables: 0,
                nbre_equipement: 0,
                agents: 0,
                paiement: 0,
                contribuableActuel: 0,
                prevuActuel: 0,
                paiementActuel:0
            },
            tableau: [],
            montantTotal: 0,
            entite: null,
            ms: [],
            from: '',
            to: '',
            agentChat: [],
            selectedAnnee: null,
            departement: null,
            quartier: null,
            zone:null,
            secteur: null,
            selectedEntite: null,
            daterange: null,
            selectedDepartement: null,
            selectedQuartier: null,
            selectedZone: null,
            selectedSecteur: null,
            ramificationRepo: new RamificationRepository(),
            dashboardRepo: new DashboardRepository(),
            entiteRepo: new EntiteRepository(),
            myValue: '',
            listCollectivites: [],
            listDepartements: [],
            listQuartiers: [],
            listZones: [],
            listSecteurs: [],

        }
    },
    methods:{

        onDateChange(){
            this.refresh();
        },
        refresh(){
            if (this.entite === null ){
                this.dashboardRepo.dashboardByEntite(this.ids, null, this.ms, this.from, this.to, this.selectedAnnee, this.user.id)
                    .then(response => {
                        this.dashboard = response.stats;
                        this.tableau = response.details;
                        this.isLoading =false;
                    });
            }
            if (this.entite != null && this.departement === null)
                this.dashboardRepo.dashboardByEntite(this.entite.id, null , null, this.from, this.to, this.selectedAnnee, this.user.id)
                    .then(response => {
                        this.dashboard = response.stats;
                        this.tableau = response.details;
                        this.isLoading =false;

                    })
            if (this.entite != null && this.departement != null && this.quartier == null){
                this.dashboardRepo.dashboardByEntite(this.entite.id,this.departement.id, this.ms, this.from, this.to, this.selectedAnnee, this.user.id)
                    .then(response => {
                        this.dashboard = response.stats;
                        this.tableau = response.details;
                        this.isLoading =false;

                    })
            }
            if (this.entite != null && this.departement != null && this.quartier != null && this.zone === null){
                this.dashboardRepo.dashboardByEntite(this.entite.id,this.quartier.id, this.ms, this.from, this.to, this.selectedAnnee, this.user.id)
                    .then(response => {
                        this.dashboard = response.stats;
                        this.tableau = response.details;
                        this.isLoading =false;

                    })
            }
            if (this.entite != null && this.departement != null && this.quartier != null && this.zone != null && this.secteur === null){
                this.dashboardRepo.dashboardByEntite(this.entite.id,this.zone.id, this.ms, this.from, this.to, this.selectedAnnee, this.user.id)
                    .then(response => {
                        this.dashboard = response.stats;
                        this.tableau = response.details;
                        this.isLoading =false;

                    })
            }
            if (this.entite != null && this.departement != null && this.quartier != null && this.zone != null && this.secteur != null){
                this.dashboardRepo.dashboardByEntite(this.entite.id,this.secteur.id, this.ms, this.from, this.to, this.selectedAnnee, this.user.id)
                    .then(response => {
                        this.dashboard = response.stats;
                        this.tableau = response.details;
                        this.isLoading =false;

                    })
            }
        },
        checkDate(type){
            if (type === 'day')
                return this.daterange === moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD") || this.daterange === moment().format("YYYY-MM-DD")
            if (type === 'week')
                return this.daterange === moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD")
            if (type === 'month')
                return this.daterange ===moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD")
            if (type === 'year')
                return this.daterange ===moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD")

        },
        filterByDay() {
            this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
            this.from = this.to = moment().format("YYYY-MM-DD")
            var index = this.fields.findIndex((obj) => obj.colonne === 'contrib.createdAt');
            if (index !== -1) {
                this.fields.splice(index, 1);
            }
            index = this.fields.findIndex((obj) => obj.colonne === 'contrib.createdAt');
            if (index !== -1) {
                this.fields.splice(index, 1);
            }
            this.fields.push({colonne: 'contrib.createdAt', operator: 'dateBetween', x: this.from, y:this.to})

            this.refresh();
            // console.log(this.daterange)
        },
        filterByYesterday() {
            this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
            this.from = moment().subtract(1, 'days').format("YYYY-MM-DD")
            this.to = moment().subtract(1, 'days').format("YYYY-MM-DD")
            var index = this.fields.findIndex((obj) => obj.colonne === 'contrib.createdAt');
            if (index !== -1) {
                this.fields.splice(index, 1);
            }
            index = this.fields.findIndex((obj) => obj.colonne === 'contrib.createdAt');
            if (index !== -1) {
                this.fields.splice(index, 1);
            }
            this.fields.push({colonne: 'contrib.createdAt', operator: 'dateBetween', x: this.from, y:this.to})
            this.refresh();
            //console.log(this.daterange)
        },
        filterByLastWeek() {
            this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
            this.from = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD")
            this.to = moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");

            this.refresh();
            //console.log(this.daterange)
        },
        filterByLastMonth() {
            this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
            this.from = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD")
            this.to = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")

            this.refresh();
            //console.log(this.daterange)
        },
        filterByWeek() {

            this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek').format("YYYY-MM-DD");
            this.from = moment().startOf('isoWeek').format("YYYY-MM-DD")
            this.to = moment().endOf('isoWeek').format("YYYY-MM-DD")
            // console.log(this.daterange);

            this.refresh();
        },
        filterByMonth() {
            this.daterange = moment().startOf('month').format("YYYY-MM-DD") + ' au ' + moment().endOf('month').format("YYYY-MM-DD");
            this.from = moment().startOf('month').format("YYYY-MM-DD")
            this.to = moment().endOf('month').format("YYYY-MM-DD")

            this.refresh();
        },
        filterByYear() {
            this.daterange = moment().startOf('year').format("YYYY-MM-DD") + ' au ' + moment().endOf('year').format("YYYY-MM-DD");
            this.from = moment().startOf('year').format("YYYY-MM-DD")
            this.to = moment().endOf('year').format("YYYY-MM-DD")

            this.refresh();
            // console.log(' au ' + moment().endOf('year').format("YYYY-MM-DD"))
        },
        formattedNumber(number) {
            if (number != null)
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            return 0;
        },
        getDirectParentIds(){


        },
        findDepartement(branch) {

            // Vérifiez si l'ID de la branche est null
            if (branch.parent === null) {

                return branch;

            }else{
                const parent = this.findDepartement(branch.parent);
                if (parent) {

                    return parent; // Si le parent est trouvé dans les ramifications, retournez-le

                }
            }

            return null;
        },

        selectEntite(val){
            this.entite = val;
            this.isLoading=true;
            this.departement = null;
            this.ids = [];
            this.ids.push(val.id)
            this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null'}], 'ent.nom', 'asc', 30, 1)
                .then(response => {
                    console.log(response)
                    response.data.forEach(item => {
                        this.listDepartements.push({id: item.id, text: item.nom});
                        this.isLoading=false;
                        this.refresh();
                    })
                });

        },
        changeEntite(val){
            this.entite = val;
            this.selectedEntite = val;
        },
        changeDepartement(val) {
            this.selectedDepartement = val;
        },
        async selectDepartement(val) {
            this.isLoading = true;
            this.ms = [];
            this.departement = val;
            this.quartier = null;
            this.dashboard = {
                prevu: 0,
                contribuables: 0,
                nbre_equipement:0,
                agents: 0, paiement: 0
            };
            this.listQuartiers = [];
            this.listZones = [];
            this.listSecteurs = [];
            if (this.user.ramifications.length > 0) {
                this.user.ramifications.forEach(item => {
                    console.log(item)
                    if(item.type.nom === 'Quartier' || item.type.nom === 'Marché'){
                        this.listQuartiers.push({id: item.id, text: item.nom})

                        this.ms = this.listQuartiers.filter(rr => rr.id === item.parent.id).map(item => item.id);
                        this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.ms}], 'ram.nom', 'asc', 3000, 1)
                            .then(zone => {
                                zone.data.forEach(item => {
                                    this.isLoading = false

                                    this.ms.push(item.id.toString())
                                })
                                this.listZones = zone.data.map(item => ({id: item.id, text: item.nom}));

                                this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.nom', 'asc', 3000, 1)
                                    .then(secteurs => {
                                        secteurs.data.forEach(item => {
                                            this.ms.push(item.id)
                                        })

                                        this.refresh();

                                    })



                            })
                    }
                    if (item.type.nom === 'Zone'){
                        this.ramificationRepo.search([{colonne: 'ram.id', operator: 'eq', value: item.parent.parent.id}], 'ent.nom', 'asc', 3000, 1)
                            .then(quartier => {
                                this.listQuartiers.push({id: quartier.id, text: quartier.nom})
                                this.ms = this.listQuartiers.map(item => item.id);

                                this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.ms}], 'ent.nom', 'asc', 3000, 1)
                                    .then(zone => {
                                        zone.data.forEach(item => {
                                            this.ms.push(item.id.toString())
                                        })
                                        this.listZones = zone.data.map(item => ({id: item.id, text: item.nom}));

                                        this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.nom', 'asc', 3000, 1)
                                            .then(secteurs => {
                                                secteurs.data.forEach(item => {
                                                    this.ms.push(item.id)
                                                })

                                                this.refresh();

                                            })
                                    })
                            })
                    }
                })
            }else{
                this.ms.push(val.id.toString())

                await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 3000, 1)
                    .then(response => {
                        response.data.forEach(item => {
                            this.listQuartiers.push({id: item.id, text: item.nom})
                        })
                        this.ms = this.listQuartiers.map(item => item.id);
                        this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.ms}], 'ent.nom', 'asc', 3000, 1)
                            .then(zone => {
                                zone.data.forEach(item => {
                                    this.ms.push(item.id.toString())
                                })
                                this.listZones = zone.data.map(item => ({id: item.id, text: item.nom}));

                                this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.nom', 'asc', 3000, 1)
                                    .then(secteurs => {
                                        secteurs.data.forEach(item => {
                                            this.ms.push(item.id)
                                        })

                                        this.refresh();

                                    })
                            })
                    })
            }


        },
        changeQuartier(val) {
            this.selectedQuartier = val;
        },
        selectQuartier(val) {
            this.ms = [];
            this.isLoading=true;
            this.quartier = val;
            this.zone = null;
            this.dashboard = {
                prevu: 0,
                contribuables: 0,
                nbre_equipement: 0,
                agents: 0,
                paiement: 0
            };
            this.listZones = [];
            this.listSecteurs = [];
            this.ms.push(val.id.toString())
            this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
                .then(response => {
                    response.data.forEach(item => {
                        this.listZones.push({id: item.id, text: item.nom})
                        this.ms.push(item.id.toString());
                    })

                    this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.id', 'asc', 3000, 1)
                        .then(secteurs => {
                            secteurs.data.forEach(item => {
                                this.ms.push(item.id.toString());
                            })

                            this.refresh()

                        })
                    //this.ms.push(this.listZones.map(item => item.id))

                })


        },
        changeZone(val) {
            this.selectedZone = val;
        },
        selectZone(val) {
            this.isLoading=true;
            this.zone = val;
            this.secteur = null;
            this.dashboard = {
                prevu: 0,
                contribuables: 0,
                nbre_equipement: 0,
                agents: 0,
                paiement: 0
            };
            this.listSecteurs = [];
            this.ms = [];
            this.ms.push(val.id)
            this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
                .then(response => {
                    response.data.forEach(item => {
                        this.listSecteurs.push({id: item.id, text: item.nom})
                        this.ms.push(item.id)
                    })

                    this.refresh()
                })

        },
        changeSecteur(val){
            this.selectedSecteur = val;
        },
        selectSecteur(val){
            this.isLoading=true;
            this.secteur = val;
            this.dashboard= {
                prevu: 0,
                contribuables: 0,
                nbre_equipement: 0,
                agents: 0,
                paiement: 0
            };
            this.listSecteurs = [];
            this.ms = [];
            this.ms.push(val.id)
            this.refresh()


        },
    },
    created() {

        this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
        this.user = this.$store.state.user;
        console.log(this.user)
        localStorage.setItem('selectedAnnee', moment().format("YYYY"))
        this.$store.dispatch("setAnnee", JSON.parse(localStorage.getItem('selectedAnnee')));
        this.selectedAnnee = this.$store.state.selectedAnnee;
        console.log(this.selectedAnnee)
        this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment(). format("YYYY-MM-DD");
        this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
        this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[1];


        if (this.user.groupe.nom === 'Super Admin'){
            this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
                .then(response => {
                    this.ids = [];
                    this.ids = response.data.map(item => item.id);
                    response.data.forEach(item => {
                        this.listCollectivites.push({id: item.id, text: item.nom});

                    })
                    this.refresh();


                })

        }
        else{
            this.user.entites.forEach(item => {
                this.listCollectivites.push({id: item.id, text: item.nom});
                this.ids.push(item.id);
            })

        }

    },
    components: {
        Select2,
        Loading,
        Flatpickr

    }

}
</script>