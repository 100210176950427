import axios from 'axios';
import Swal from 'sweetalert2'

export default class API {
    username = 'oumou.diarra';
    password = 'sorofing';
    prod= 'https://apidistrict.klispay.com/api';
    dev= 'http://127.0.0.1:8000/api';
    stagging= 'https://apiclassic.klispay.tech/api';

    token = null;
    fields= [
        { colonne: '', operator: '', value: '' }
    ];
    statutOptions = [
        'Validé',
        'Corrigé',
        'Enrolé',
    ];
    transStatutOptions = [
        'Confirmé',
        'En attente de confirmation',
        'Failed',
    ];
    operatorOptions= [
        { text: '=', value: 'eq' },
        { text: '<>', value: 'not eq' },
        { text: '<', value: 'lt' },
        { text: '<=', value: 'lte' },
        { text: '>', value: 'gt' },
        { text: '>=', value: 'gte' },
        { text: 'Not like', value: 'not like' },
        { text: 'Like', value: 'like' },
        { text: 'Vide', value: 'is null' },
        { text: 'Non Vide', value: 'is not null' },
        { text: 'Entre', value: 'between' },
        { text: 'Plage date', value: 'dateBetween' },
        { text: 'In', value: 'in' },
        { text: 'Not in', value: 'not in' },
    ];
    sortOrderOptions= [
        { text: 'asc', value: 'asc' },
        { text: 'desc', value: 'desc' }
    ];
    itemsPerPageOptions= [
        { text: '50', value: '50' },
        { text: '100', value: '100' },
        { text: '200', value: '200' },
        { text: '500', value: '500' }
    ];

    constructor() {
        this.api = axios.create({
            baseURL: this.stagging,
        });
    }

     //http://172.16.6.240:8000/api
    //https://api.klispay.com/api

    async getToken() {
        try {
            let data = {username: this.username, password: this.password}

            const response =  await this.api.post('/login_check', data, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });

            this.token = response.data.token;
            return response;
        }catch (e) {
            this.initializeToast("error", "Une erreur s'est produite !")

        }
    }

    async listing(url, fields, orderBy, sortOrder, itemPerPage, currentPage) {

        try {
            await this.getToken();


            let list;
            if(fields ==null){
                list =  this.api.get(url);
            }else {
                let query = '';
                if(url.includes('?'))
                     query = url+'&orderBy=' + orderBy + '&sortOrder=' + sortOrder + '&itemperpage=' + itemPerPage + '&page=' + currentPage;
                else
                    query = url+'?orderBy=' + orderBy + '&sortOrder=' + sortOrder + '&itemperpage=' + itemPerPage + '&page=' + currentPage;

                fields.forEach(function (element) {
                    let tableau = null;
                    switch (element.operator) {
                        case 'eq':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'not eq':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'gt':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'gte':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'lt':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'lte':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'like':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'not like':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'is null':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator;
                            break;
                        case 'is not null':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator;
                            break;
                        case 'in':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'not in':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value;
                            break;
                        case 'between':
                            tableau = element.value.split('et');
                            console.log(tableau[0]);
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + tableau[0].trim() + '&filter[' + element.colonne + '][y]=' + tableau[1].trim();
                            //console.log(query);

                            break;
                        case 'dateBetween':
                           // tableau = element.value.split('au');
                            //console.log(tableau[0]);
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.x.trim() + '&filter[' + element.colonne + '][y]=' + element.y.trim();
                            //console.log(query);

                            break;


                    }
                });
                //console.log(query);
                list = await this.api.get(query, {
                    headers: {
                        Authorization: 'Bearer '+this.token
                    }
                });
            }
            //console.log(list.data);
            return list.data;
        }catch (e) {
            this.initializeToast("error", "Une erreur s'est produite !")

        }
    }
    async listingContrib(url, fields, orderBy, sortOrder, itemPerPage, currentPage) {

        try {
            await this.getToken();

            let list;
            if(fields ==null){
                list =  this.api.get(url);
            }else {
                let query = '';
                if(url.includes('?'))
                     query = url+'&orderBy=' + orderBy + '&sortOrder=' + sortOrder + '&itemperpage=' + itemPerPage + '&page=' + currentPage;
                else
                    query = url+'?orderBy=' + orderBy + '&sortOrder=' + sortOrder + '&itemperpage=' + itemPerPage + '&page=' + currentPage;

                fields.forEach(function (element) {
                 //   let tableau = null;
                    switch (element.operator) {
                        case 'eq':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'not eq':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'gt':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'gte':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'lt':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'lte':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'like':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'not like':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'is null':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator;
                            break;
                        case 'is not null':
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator;
                            break;
                        case 'in':
                            console.log(element.value)

                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;
                        case 'not in':
                            console.log(element.value)
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.value   ;
                            break;

                        case 'dateBetween' ||  'between'  :
                         //   tableau = element.value.split('et');
                         //   console.log(tableau[0]);
                            query = query + '&filter[' + element.colonne + '][type]=' + element.operator + '&filter[' + element.colonne + '][x]=' + element.x.trim() + '&filter[' + element.colonne + '][y]=' + element.y.trim()   ;
                            //console.log(query);

                            break;


                    }
                });
                //console.log(query);
                list = await this.api.get(query, {

                    headers: {
                        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2ODE1Njk5MjQsImV4cCI6MTcxMzE5MjMyNCwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoib3Vtb3UuZGlhcnJhIn0.cFtYjMp7kWMRLLWDbXmt11TAY_T17rJfxR8ARQt7RNHqPNHL8T156dPPrZi6H4UDtSU5DFaQrtUvEhzq-Wdvbs38Bk1jIeAhx7VzWUG3zcQmg_3Oec7UESDhqTbez0ZWrXrAWwoO1n2ysZN4hbD04JTy3s1t_uStB8laWqvz0k_Y1pgTol53kpx7SVgm9Ioa-v4gXVpqCxNLi1TOPXoDgY-ZoUba5CJRzxNmHDmY1bVFqxstSBIWA9Kgm3vDjLvM8_CgSawCPoo8dvfrewQ-HM37xB5uKoW5K6OAz2Z68uLJ7Oro1KLJG3cv4MamvzxQ-MeVwEvAb9E260Wv8Csfz9E4UOwDdUNecvCOns9HMqzlgeP7jbQFQ4Vo9vFbAq1VigvqHJFAcJX8RfzzQzeyHOTz-dpq78JkmcPYJuMNcum13rHSoKlIUEGlTlBbfituU3OvWw1UZBOzzAoxlLBvN0oC39AMlLYFc_A0d43Q2bp02DNKchFiyfmEVy3L7_NkULx7RWPBrVyVwZAgPHATI-uqcJKKFzYqkNA5Pyq6ELwk1WvLDNZqkJFIi58t2QUlaqCg-nWiWLyFwMgk7QcpUK5Fw_Hw0cJikiQrJMtKYj02PLMNrzXTH13oZ8laEola1qRoB-WitGLq1lhAPh5EaDJJj7hd_kEWcEZOwXhIIwQ'
                    }
                });
            }
            //console.log(list.data);
            return list.data;
        }catch (e) {
            this.initializeToast("error", "Une erreur s'est produite !")

        }
    }
    async post(url, data) {

        try {
            await this.getToken();
            //console.log(this.token)
            const response = await this.api.post(url, data,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer '+this.token
                }
            });
            if (response.status === 201 || response.status === 200){
                this.initializeToast("success", "L'élément a bien été ajouté !")

                return response.data;
            }else
                this.initializeToast("error", "Une erreur s'est produite !")



        }catch (e) {
            this.initializeToast("error", "Une erreur s'est produite !")

        }
    }
    async poste(url, data) {

        try {
            await this.getToken();
            //console.log(this.token)
            const response = await this.api.post(url, data,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer '+this.token
                }
            });
            //this.initializeToast("success", "L'élément a bien été ajouté !")

            return response.data;


        }catch (e) {
            this.initializeToast("error", "Une erreur s'est produite !")

        }
    }
    async findOne(url) {

        try {
            await this.getToken();
            // console.log(this.token)
            const response = await this.api.get(url,{
                headers: {
                    Authorization: 'Bearer '+this.token
                }
            });
            if (response.status === 200){
                //this.initializeToast("success", "L'élément a bien été ajouté !")

                return response.data;
            }else
                this.initializeToast("error", "Une erreur s'est produite !")



        }catch (e) {
            this.initializeToast("error", "Une erreur s'est produite !")

        }
    }
    async patch(url, data) {

        try {
            await this.getToken();
            const response = await this.api.patch(url, data,{
                headers: {
                    Authorization: 'Bearer '+this.token,
                    'Content-Type': 'application/merge-patch+json'
                }
            });
            if (response.status === 200){
                this.initializeToast("success", "L'élément a bien été modifié !")

                return response.data;
            }else
                this.initializeToast("error", "Une erreur s'est produite !")
        }catch (e) {
            this.initializeToast("error", "Une erreur s'est produite !")

        }
    }
    async put(url, data) {

        try {
            await this.getToken();
            const response = await this.api.put(url, data,{
                headers: {
                    Authorization: 'Bearer '+this.token,
                   // 'Content-Type': 'application/merge-patch+json'
                }
            });
            if (response.status === 200){
                this.initializeToast("success", "L'élément a bien été modifié !")

                return response.data;
            }
        }catch (e) {
            this.initializeToast("error", "Une erreur s'est produite !")

        }
    }
    async delete(url) {

        try {
            await this.getToken();
            const response = await this.api.delete(url,{
                headers: {
                    Authorization: 'Bearer '+this.token,
                    'Content-Type': 'application/merge-patch+json'
                }
            });
            if (response.status === 204){
                this.initializeToast("success", "L'élément a bien été supprimé !")
                return true;
            }
            if (response.status !== 500)
                this.initializeToast("error", "Impossible de supprimer cet élément car toutes les données liées à cet élément seront supprimé !")


        }catch (e) {
            this.initializeToast("error", "Impossible de supprimer cet élément car toutes les données liées à cet élément seront supprimé !")

        }
    }
    loginCheck(email, password, token) {

        const response =  this.api.post('/v2/users/check', {email, password},{
            headers: {
                Authorization: 'Bearer '+token
            }
        });
        return response;
    }

    getAllCollectivite(token) {


        const response =  this.api.get('/v2/entites?filter[te.id][type]=eq&filter[te.id]=1',{
            headers: {
                Authorization: 'Bearer '+token
            }
        });
        return response;
    }

    initializeToast(icon, message){
        let Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
        });
        Toast.fire({
            icon: icon,
            title: message
        })

    }
}